jQuery.fn.InitializeTypeAhead = function (option) {
    var defaults = {
        initialModelUrl: null,
        serviceUrl: null,
        siteSearchUrl: null,
        queryKeyword: null
    };
    var option = $.extend({}, defaults, option);
    var pageContent = $(this);
    var typeAheadTimer;
    var setGroup = false;

    var viewModel = {};
    viewModel.Keyword = ko.observable('');
    viewModel.TypeAheadResultGroup = ko.observableArray();
    viewModel.IsSearchRunning = ko.observable(false);
    viewModel.selectedResult = ko.observable();
    viewModel.currentNdx = -1;
    $.ajax({
        url: option.initialModelUrl,
        type: "POST",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        success: function (data) {
            ko.mapping.fromJS(data, {}, viewModel.TypeAheadResultGroup);
        },
        error: function (xhr, textStatus, errorThrown) {
            console.log(xhr);
            console.log("textStatus : " + textStatus);
            console.log("errorThrown : " + errorThrown);
        }
    });

    viewModel.Search = function (section) {
        if(viewModel.selectedResult() != null && viewModel.selectedResult().Url() && viewModel.selectedResult().Url().length > 0)
            window.location.href = viewModel.selectedResult().Url();
        else{
            var siteSearchUrl = option.siteSearchUrl + "?" + option.queryKeyword + "=" + encodeURIComponent(viewModel.Keyword());
            window.location.href = siteSearchUrl;
        }
    }

    viewModel.LoadDataFromServer = function () {
        viewModel.IsSearchRunning(true);
        viewModel.currentNdx = -1;
        viewModel.selectedResult(null);
        var searchTerm = viewModel.Keyword();
        $.ajax({
            url: option.serviceUrl + "?query=" + encodeURIComponent(viewModel.Keyword()),
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            cache: false,
            success: function (data) {
                var cleanFilter = searchTerm.toLowerCase().replace(/[^a-zA-Z0-9 :]/g, "")
                var findArray = cleanFilter.split(/,| /);
                findArray = $.grep(findArray, function (n) { return (n); });
                //each category
                var preservedCaseKeywords = [];
                var keywordIndex = 0;
                ko.utils.arrayForEach(data, function (item) {
                    //each result item
                    keywordIndex = 0;
                    ko.utils.arrayForEach(item.Results, function (childItem) {
                        childItem.DisplayMatched = childItem.Name;
                        //each keyword to highlight in the title
                        ko.utils.arrayForEach(findArray, function (searchItem) {
                            if (searchItem.length > 1) {
                                var regEx = new RegExp('(' + searchItem + ')', "i");
                                while(regEx.test(childItem.DisplayMatched)) {
                                preservedCaseKeywords.push(regEx.exec(childItem.DisplayMatched)[0]);
                                childItem.DisplayMatched = childItem.DisplayMatched.replace(regEx, "###_" + keywordIndex + "_###");
                                keywordIndex++;
                                }
                            }
                        });
                    //"<strong class='keyword'>$1</strong>"
                    });
                
                    keywordIndex = 0;
                    var containsPlaceholder = new RegExp('(###_)', "i");
                    //to prevent searching for strings contained in the html match getting double-nested, replace them all after
                    ko.utils.arrayForEach(item.Results, function (childItem) {
                        ko.utils.arrayForEach(findArray, function (searchItem) {
                            if (searchItem.length > 1) {
                                while(containsPlaceholder.test(childItem.DisplayMatched)) {
                                var originalCase = preservedCaseKeywords.shift();
                                var regEx = new RegExp('(' + "###_" + keywordIndex + "_###" + ')', "i");
                                childItem.DisplayMatched = childItem.DisplayMatched.replace(regEx, "<strong class='keyword'>" + originalCase + "</strong>");
                                keywordIndex++;
                                }
                            }
                        });
                    });
                });
                if(setGroup)
                ko.mapping.fromJS(data, {}, viewModel.TypeAheadResultGroup);
                viewModel.IsSearchRunning(false);
            },
            error: function (xhr, textStatus, errorThrown) {
                console.log(xhr);
                viewModel.TypeAheadResultGroup.removeAll();
                console.log("textStatus : " + textStatus);
                console.log("errorThrown : " + errorThrown);
            }
        });
    }
    //these functions handle using arrow keys in the list-style
    //see GlobalMenuBar for example of use
    viewModel.selectPrevious = function () {
        if(viewModel.currentNdx === -1)
            viewModel.currentNdx = 0;
        else
            viewModel.currentNdx--;
        viewModel.selectedResult(this.TypeAheadResultGroup()[0].Results()[viewModel.currentNdx]);
    };

    viewModel.selectNext = function () {
        if(viewModel.currentNdx === -1)
            viewModel.currentNdx = 0;
        else
            viewModel.currentNdx++;
        viewModel.selectedResult(this.TypeAheadResultGroup()[0].Results()[viewModel.currentNdx]);
    };
    ko.bindingHandlers.bindTypeAheadKeyUp = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            var options = valueAccessor();
            var allBindings = allBindingsAccessor();
            var observable = options.observable;

            $(element).keyup(function (event) {
                var $this = $(this);
                var val = $this.val();
                var keycode = (event.keyCode ? event.keyCode : event.which);

                if (keycode == '38') {
                    viewModel.selectPrevious();
                    return false;
                } else if (keycode == '40') {
                    viewModel.selectNext();
                    return false;
                }
                if (!!val && val.length >= 3) {
                    clearTimeout(typeAheadTimer);
                    setGroup = true;
                    typeAheadTimer = setTimeout(viewModel.LoadDataFromServer, 300);
                }
                else {
                    setGroup = false;
                    viewModel.TypeAheadResultGroup.removeAll();
                }

                if (keycode == '13') {
                    $('.typeahead-results').hide();
                }
                return false;
            });
        }
    };

    ko.bindingHandlers.isFocusedEl = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {

            if( /Android|iPhone|iPad|BlackBerry|IEMobile/i.test(navigator.userAgent) ) {

            
            var $textboxInput = $(element);

               $textboxInput.on('focus.customEv', function() {
                    $textboxInput
                        .closest('.panel-back').addClass('adjust-input-position');

                    
                    $textboxInput.on('blur.customEv', function() {
                        $textboxInput
                            .closest('.panel-back').removeClass('adjust-input-position');

                         $textboxInput.off('blur.customEv');
                    });
                });
            }
        }
    };

    // Apply Bindings
    $.each(pageContent, function (i, o) {
        ko.applyBindings(viewModel, o);
    });
};
